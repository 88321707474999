const ProfileOptions = () => {
  return (
    <section className="dashboard-form__options">
      <div className="dashboard-form__options__title">
        <h6>Profile</h6>
      </div>
    </section>
  );
};

export default ProfileOptions;
