export const LANGUAGE = "LANGUAGE";

export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const CLEAR_VALUES = "CLEAR_VALUES";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const SET_EDIT_USER = "SET_EDIT_USER";

export const EDIT_USER_BEGIN = "EDIT_USER_BEGIN";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const VERIFY_TOKEN_BEGIN = "VERIFY_TOKEN_BEGIN";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";

export const FORGOT_PASSWORD_BEGIN = "FORGOT_PASSWORD_BEGIN";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_BEGIN = "RESET_PASSWORD_BEGIN";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const LOGOUT_USER = "LOGOUT_USER";

export const GET_MATERIALS_CONSUMPTION_BEGIN =
  "GET_MATERIALS_CONSUMPTION_BEGIN";
export const GET_MATERIALS_CONSUMPTION_SUCCESS =
  "GET_MATERIALS_CONSUMPTION_SUCCESS";

export const GET_MATERIAL_INVENTORY_BEGIN = "GET_MATERIAL_INVENTORY_BEGIN";
export const GET_MATERIAL_INVENTORY_SUCCESS = "GET_MATERIAL_INVENTORY_SUCCESS";

export const GET_USERS_BEGIN = "GET_USERS_BEGIN";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL_CONFIRM = "OPEN_MODAL_CONFIRM";
export const CLOSE_MODAL_CONFIRM = "CLOSE_MODAL_CONFIRM";

// Stats
export const GET_DAILY_PRODUCTION_BEGIN = "GET_DAILY_PRODUCTION_BEGIN";
export const GET_DAILY_PRODUCTION_SUCCESS = "GET_DAILY_PRODUCTION_SUCCESS";

export const GET_MATERIAL_TOLERANCE_BEGIN = "GET_MATERIAL_TOLERANCE_BEGIN";
export const GET_MATERIAL_TOLERANCE_SUCCESS = "GET_MATERIAL_TOLERANCE_SUCCESS";

export const GET_FORMULA_TOLERANCE_BEGIN = "GET_FORMULA_TOLERANCE_BEGIN";
export const GET_FORMULA_TOLERANCE_SUCCESS = "GET_FORMULA_TOLERANCE_SUCCESS";

// Materials
export const GET_MATERIALS_BEGIN = "GET_MATERIALS_BEGIN";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";

export const CREATE_MATERIAL_BEGIN = "CREATE_MATERIAL_BEGIN";
export const CREATE_MATERIAL_SUCCESS = "CREATE_MATERIAL_SUCCESS";
export const CREATE_MATERIAL_ERROR = "CREATE_MATERIAL_ERROR";

export const SET_EDIT_MATERIAL = "SET_EDIT_MATERIAL";

export const EDIT_MATERIAL_BEGIN = "EDIT_MATERIAL_BEGIN";
export const EDIT_MATERIAL_SUCCESS = "EDIT_MATERIAL_SUCCESS";
export const EDIT_MATERIAL_ERROR = "EDIT_MATERIAL_ERROR";

export const DELETE_MATERIAL_BEGIN = "DELETE_MATERIAL_BEGIN";
export const DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS";
export const DELETE_MATERIAL_ERROR = "DELETE_MATERIAL_ERROR";

// Formulas
export const GET_FORMULAS_BEGIN = "GET_FORMULAS_BEGIN";
export const GET_FORMULAS_SUCCESS = "GET_FORMULAS_SUCCESS";

export const GET_FORMULA_BY_ID_BEGIN = "GET_FORMULA_BY_ID_BEGIN";
export const GET_FORMULA_BY_ID_SUCCESS = "GET_FORMULA_BY_ID_SUCCESS";

export const CREATE_FORMULA_BEGIN = "CREATE_FORMULA_BEGIN";
export const CREATE_FORMULA_SUCCESS = "CREATE_FORMULA_SUCCESS";
export const CREATE_FORMULA_ERROR = "CREATE_FORMULA_ERROR";

export const SET_DELETE_FORMULA = "SET_DELETE_FORMULA";
export const SET_EDIT_FORMULA_BEIGN = "SET_EDIT_FORMULA_BEIGN";
export const SET_EDIT_FORMULA_SUCCESS = "SET_EDIT_FORMULA_SUCCESS";

export const EDIT_FORMULA_BEGIN = "EDIT_FORMULA_BEGIN";
export const EDIT_FORMULA_SUCCESS = "EDIT_FORMULA_SUCCESS";
export const EDIT_FORMULA_ERROR = "EDIT_FORMULA_ERROR";

export const DELETE_FORMULA_BEGIN = "DELETE_FORMULA_BEGIN";
export const DELETE_FORMULA_SUCCESS = "DELETE_FORMULA_SUCCESS";
export const DELETE_FORMULA_ERROR = "DELETE_FORMULA_ERROR";

// Warehouse
export const GET_WAREHOUSES_BEGIN = "GET_WAREHOUSES_BEGIN";
export const GET_WAREHOUSES_SUCCESS = "GET_WAREHOUSES_SUCCESS";

export const CREATE_WAREHOUSE_BEGIN = "CREATE_WAREHOUSE_BEGIN";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const CREATE_WAREHOUSE_ERROR = "CREATE_WAREHOUSE_ERROR";

export const SET_EDIT_WAREHOUSE = "SET_EDIT_WAREHOUSE";

export const EDIT_WAREHOUSE_BEGIN = "EDIT_WAREHOUSE_BEGIN";
export const EDIT_WAREHOUSE_SUCCESS = "EDIT_WAREHOUSE_SUCCESS";
export const EDIT_WAREHOUSE_ERROR = "EDIT_WAREHOUSE_ERROR";

export const DELETE_WAREHOUSE_BEGIN = "DELETE_WAREHOUSE_BEGIN";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_ERROR = "DELETE_WAREHOUSE_ERROR";

export const WAREHOUSE_OPERATIONS_BEGIN = "WAREHOUSE_OPERATIONS_BEGIN";
export const WAREHOUSE_OPERATIONS_SUCCESS = "WAREHOUSE_OPERATIONS_SUCCESS";
export const WAREHOUSE_OPERATIONS_ERROR = "WAREHOUSE_OPERATIONS_ERROR";

// export const GET_WAREHOUSE_MANAGEMENT_BEGIN = "GET_WAREHOUSE_MANAGEMENT_BEGIN";
// export const GET_WAREHOUSE_MANAGEMENT_SUCCESS =
//   "GET_WAREHOUSE_MANAGEMENT_SUCCESS";

export const GET_WAREHOUSE_OPERATIONS_BEGIN = "GET_WAREHOUSE_OPERATIONS_BEGIN";
export const GET_WAREHOUSE_OPERATIONS_SUCCESS =
  "GET_WAREHOUSE_OPERATIONS_SUCCESS";

export const GET_WAREHOUSE_INVENTORY_BEGIN = "GET_WAREHOUSE_INVENTORY_BEGIN";
export const GET_WAREHOUSE_INVENTORY_SUCCESS =
  "GET_WAREHOUSE_INVENTORY_SUCCESS";

export const CHANGE_PAGE = "CHANGE_PAGE";
